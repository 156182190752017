<template>
  <PromotionTemplate
    id="euro2024"
    :bannerStyle="{
      backgroundImage: `url(${rwdBannerBg})`,
      backgroundPosition: 'right top',
      backgroundSize: '100% auto',
      minHeight: `${bannerHeight}px`
    }"
    :hasBanner="banner"
    :class="bannerRwdClass"
    ref="bannerItem"
  >
    <template #bannerItem>
      <BannerItemV2
        v-bind="banner"
        :lang="imgLang"
        @getBannerWidth="getBannerWidth"
        @getCurrentBannerRwdClass="getCurrentBannerRwdClass"
      ></BannerItemV2>
    </template>

    <template #content>
      <AppPromotionInfo
        promotionTitle="olympic2024"
        claimTitle=""
        title="promotion.olympic2024.title"
        desc="promotion.olympic2024.desc"
        :eligibilityDescriptions="$t('promotion.olympic2024.eligibleDescriptions')"
        :appImage="appImage"
        appLink="https://puprime.onelink.me/O5Jx/SPORTSBONUSCLIENTPORTAL"
        tncLink="https://www.puprime.com/terms-and-conditions/one-world-one-stage-olympic-2024-terms/"
      />
    </template>
  </PromotionTemplate>
</template>

<script>
import { PROMOTION_TYPE } from '@/constants/promotion';
import PromotionTemplate from '@/components/promotion/common/PromotionTemplate.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import AppPromotionInfo from '@/components/promotion/common/AppPromotionInfo.vue';

export default {
  name: 'Euro2024',
  components: {
    PromotionTemplate,
    BannerItemV2,
    AppPromotionInfo
  },
  data() {
    return {
      banner: null,
      bannerRwdClass: null,
      bannerWidth: null,
      bannerHeight: 320,
      rwdBannerBg: null,
      appImage: ''
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.OLYMPIC2024) || {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    },
    imgLang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    bannerWidth: {
      immediate: true,
      handler() {
        if (this.banner?.img) {
          let banner = this.banner?.img;
          let oriWidth = 1660;
          const oriHeight = 320;

          switch (this.bannerRwdClass) {
            case 'long':
              oriWidth = 2048;
              banner = this.banner?.imgLarge;
              break;
            case 'mid':
              oriWidth = 1660;
              banner = this.banner?.img;
              break;
            case 'short':
              oriWidth = 414;
              banner = this.banner?.imgSmall;
              break;
          }
          this.bannerHeight = (oriHeight / oriWidth) * this.bannerWidth;
          this.rwdBannerBg = banner;
        }
      }
    }
  },
  async mounted() {
    this.getBannerSetting();
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    },
    getCurrentBannerRwdClass(bannerRwdClass) {
      this.bannerRwdClass = bannerRwdClass;
    },
    getBannerWidth(bannerWidth) {
      this.bannerWidth = bannerWidth;
    },
    handleResize() {
      if (window.innerWidth < 500) {
        this.appImage = require('@/assets/images/promotion/euro2024/euro2024_desc-small.png');
      } else {
        this.appImage = require('@/assets/images/promotion/euro2024/euro2024_desc.png');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#euro2024 /deep/ {
  .banner-container {
    min-height: auto;
    .new-banner-image {
      position: inherit;
    }
  }
}
</style>
